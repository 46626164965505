/* @media screen and (max-width:767px){} */

@media all and (min-width: 1024px) {
  .App {
    text-align: center;
    width: 50%;
    padding-left: 25%;
    padding-right: 25%;
    /* height: 100%; */
    /* overflow: visible; */
    height: 100vh;
    /* height: calc(100% - 0px);
    padding-top: 40px;
    position: relative;
    padding-bottom: 19px; */
    /* background-color: rgb(250, 255, 238); */
    /* min-height: 100%; */
    /* flex 컨테이너 설정 */
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: floralwhite;
  }

  .main_wrapper {
    width: 600px;
  }
  .main_content {
    /* height: 100%; */
  }

  .main_page_content {
    margin-top: 40px;
  }

  .title_wrapper_main {
    /* margin-top: 20vh; */
  }

  .title {
    font-size: 102px;
    padding-bottom: 20px;
    /* width: 730px; */
    text-align: center;
  }
  .title_sub {
    font-size: 40px;
    /* padding-bottom: 50px; */
    /* width: 730px; */
    text-align: center;
  }
  .body {
    display: inline-block;
    text-align: center;
  }
  .PrivatePickersSlideTransition-root css-dhopo2 {
    height: 240px;
  }
  .disable {
    cursor: default !important;
    opacity: 0.7 !important;
    pointer-events: none;
  }

  .button {
    cursor: pointer;
  }

  .center {
    text-align: center;
  }

  .footer {
    text-align: center;
    padding: 10px;
    /* position: absolute; */
    /* bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
    padding-bottom: 24px;
    height: 104px;
    position: relative;
    transform: translateY(100%); */
  }

  /* ============================================================================================ */

  .menu_title {
    font-size: 32px;
    margin-bottom: 24px;
    font-weight: 700;
  }
  .menu_content_title {
    font-size: 28px;
  }
  .menu_content {
    margin-bottom: 16px;
  }

  /* ============================================================================================ */

  .padding_bottom {
    padding-bottom: 24px;
  }

  /* ============================================================================================ */
  .main_star_1 {
    position: absolute;
    top: calc(50% - 5px);
    right: calc(50% + 180px);
  }
  .main_star_2 {
    position: absolute;
    top: calc(50% - 265px);
    right: calc(50% - 90px);
  }
  /* .main_star_3 {
    position: absolute;
    top: calc(50% - 505px);
    right: calc(50% + 150px);
  } */
  .main_star_4 {
    position: absolute;
    top: calc(50% - 110px);
    right: calc(50% - 220px);
  }
  .main_star_5 {
    position: absolute;
    top: calc(50% - 250px);
    right: calc(50% - 170px);
    /* top: calc(50% - 190px);
    right: calc(50% - 170px); */
  }
  .main_star_6 {
    position: absolute;
    top: calc(50% - 90px);
    right: calc(50% + 140px);
  }
}

@keyframes animateWinner {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.winners {
  animation: animateWinner 0.5s ease forwards;
}

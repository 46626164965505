html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.start_button:hover {
  box-shadow: 3px 3px 10px -6px rgba(0, 0, 0, 0.82);
}

/** ===================================================================================== */

.main_wrapper {
  text-align: center;
  /* width: 50%; */
  /* padding-left: 25%;
  padding-right: 25%; */
  overflow: visible;
  /* height: 90vh; */
  /* height: calc(100% - 0px);
  padding-top: 40px;
  position: relative;
  padding-bottom: 19px; */
  /* background-color: rgb(250, 255, 238); */
  /* min-height: 100%; */
  /* flex 컨테이너 설정 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  max-width: 600px;
  height: 100%;
}

.main_content {
}

/** ===================================================================================== */
.h_center {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
/** ===================================================================================== */

.mt_30 {
  margin-top: 30px;
}

.font12 {
  font-size: 12px !important;
}
.font14 {
  font-size: 14px !important;
}
.font16 {
  font-size: 16px !important;
}
.font24 {
  font-size: 24px;
}
/** ===================================================================================== */
.mapContainer {
  min-height: 32vh;
  border: 3px solid black;
  margin-left: 24px;
  margin-right: 24px;
}
/** ===================================================================================== */
.button {
  cursor: pointer;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 200px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  /* padding-left: 8px !important; */
  font-size: 16px !important;
  height: 20px !important;
}
/** ===================================================================================== */

/* CSS */
.button-27 {
  appearance: none;
  background-color: #000000;
  border: 2px solid #1a1a1a;
  border-radius: 28px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 200px;
  will-change: transform;
}

.button-27:disabled {
  pointer-events: none;
}

.button-27:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-27:active {
  box-shadow: none;
  transform: translateY(0);
}

/** ===================================================================================== */

.button-28 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  border-radius: 28px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 200px;
  will-change: transform;
}

.button-28:disabled {
  pointer-events: none;
}

.button-28:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28:active {
  box-shadow: none;
  transform: translateY(0);
}

/** ===================================================================================== */

.button-28-double {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  border-radius: 28px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 8px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 200px;
  will-change: transform;
}

.button-28-double:disabled {
  pointer-events: none;
}

.button-28-double:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-28-double:active {
  box-shadow: none;
  transform: translateY(0);
}
/** ===================================================================================== */

.button-29 {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1a1a1a;
  border-radius: 80px;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 22px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
}

.button-29:disabled {
  pointer-events: none;
  background-color: powderblue;
  border-color: powderblue;
}

.button-29:hover {
  color: #fff;
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: none;
  transform: translateY(0);
}

/** ===================================================================================== */
.winner_button {
  background-color: lightpink !important;
  border-color: lightpink !important;
}
/** ===================================================================================== */
@font-face {
  font-family: "Bolderslant";
  src: url("../src/assets/Bolderslant.ttf") format("truetype");
}
@font-face {
  font-family: "cursyger";
  src: url("../src/assets/cursyger.ttf") format("truetype");
}
@font-face {
  font-family: "Hugest";
  src: url("../src/assets/Hugest.woff") format("woff");
}
@font-face {
  font-family: "LCMogi";
  src: url("../src/assets/LCMogi-A.ttf") format("truetype");
}
@font-face {
  font-family: "rondula";
  src: url("../src/assets/rondula.ttf") format("truetype");
}
@font-face {
  font-family: "YouDense";
  src: url("../src/assets/YouDense-Glass-BlowBold.ttf") format("truetype");
}
@font-face {
  font-family: "Chilgok";
  src: url("../src/assets/Chilgok_lws.ttf") format("truetype");
}
@font-face {
  font-family: "yuri";
  src: url("../src/assets/yuri.ttf") format("truetype");
}
@font-face {
  font-family: "father";
  src: url("../src/assets/father.ttf") format("truetype");
}
@font-face {
  font-family: "StayPuft";
  src: url("../src/assets/StayPuft.woff") format("woff");
}
@font-face {
  font-family: "FuturaBoldItalic";
  src: url("../src/assets/FuturaBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "futuralightbt";
  src: url("../src/assets/futuralightbt.ttf") format("truetype");
}
@font-face {
  font-family: "futura";
  src: url("../src/assets/futura.ttf") format("truetype");
}

@font-face {
  font-family: "KoddiUDOnGothic-Regular";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2105_2@1.0/KoddiUDOnGothic-Regular.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.Bolderslant {
  font-family: "Bolderslant";
}

.cursyger {
  font-family: "cursyger";
}
.Hugest {
  font-family: "Hugest";
}
.LCMogi {
  font-family: "LCMogi";
}
.rondula {
  font-family: "rondula";
}
.YouDense {
  font-family: "YouDense";
}
.Chilgok {
  font-family: "Chilgok";
}
.yuri {
  font-family: "yuri";
}
.father {
  font-family: "father";
}
.FuturaBoldItalic {
  font-family: "FuturaBoldItalic";
}
.futuralightbt {
  font-family: "futuralightbt";
}
.futura {
  font-family: "futura";
}

.KoddiUDOnGothic-Regular {
  font-family: "KoddiUDOnGothic-Regular";
}

/** ===[image]================================================================================== */

.logo_1 {
  width: -webkit-fill-available;
  content: url(../src/assets/images/logo1.png);
}

.logo_2 {
  width: -webkit-fill-available;
  content: url(../src/assets/images/logo2.png);
}
.logo_heart_in_heart {
  width: -webkit-fill-available;
  content: url(../src/assets/images/logo_heart_in_heart.png);
}
.logo_heart_in_shine {
  width: -webkit-fill-available;
  content: url(../src/assets/images/logo_heart_in_shine.png);
}
.logo_heart_shine {
  width: -webkit-fill-available;
  content: url(../src/assets/images/logo_heart_shine.png);
}
.logo_heart {
  width: -webkit-fill-available;
  content: url(../src/assets/images/logo_heart.png);
}
.logo_star {
  width: -webkit-fill-available;
  content: url(../src/assets/images/logo_star.png);
}
.star1 {
  width: -webkit-fill-available;
  content: url(../src/assets/images/star1.png);
}
.star2 {
  width: -webkit-fill-available;
  content: url(../src/assets/images/star2.png);
}
.star3 {
  width: -webkit-fill-available;
  content: url(../src/assets/images/star3.png);
}

/** ===================================================================================== */
.wcenter {
  display: flex;
  justify-content: center;
}

/** ===================================================================================== */

.w20 {
  width: 20px;
}
.h20 {
  height: 20px;
}
.w40 {
  width: 40px;
}
.h40 {
  height: 40px;
}
.w60 {
  width: 60px;
}
.h60 {
  height: 60px;
}
.w80 {
  width: 80px;
}
.h80 {
  height: 80px;
}
